import React from 'react';
import { MockData } from '../types';
import { FaBuilding, FaUserFriends, FaFemale, FaRocket, FaMoneyBillWave } from 'react-icons/fa';

interface HeaderProps {
  data: MockData;
}

function Header({ data }: HeaderProps) {
  return (
    <header className="p-2 lg:p-3 flex flex-col lg:flex-row justify-between items-center space-y-2 lg:space-y-0">
      <div className="w-full lg:w-auto text-center lg:text-right mr-2">
        <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-black montserrat-medium font-semibold">
          {data.title}
        </h1>
        <p className="text-red-600 text-sm sm:text-md md:text-lg mt-1 montserrat-medium font-semibold">
          {data.subTitle}
        </p>
      </div>

      <div className="bg-gray-800 text-white p-2 lg:p-3 rounded-lg flex flex-wrap justify-center gap-2 sm:gap-3 lg:gap-4 w-full lg:w-auto">
        <div className="flex items-center space-x-2">
          <FaBuilding className="text-base sm:text-lg md:text-xl lg:text-2xl" />
          <div className="text-left">
            <h2 className="text-xs sm:text-sm md:text-base font-bold">{data.noOfCompanies}</h2>
            <p className="text-xxs sm:text-xs md:text-sm">Number of Companies</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <FaUserFriends className="text-base sm:text-lg md:text-xl lg:text-2xl" />
          <div className="text-left">
            <h2 className="text-xs sm:text-sm md:text-base font-bold">{data.noOfFounders}</h2>
            <p className="text-xxs sm:text-xs md:text-sm">Number of Founders</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <FaFemale className="text-base sm:text-lg md:text-xl lg:text-2xl" />
          <div className="text-left">
            <h2 className="text-xs sm:text-sm md:text-base font-bold">{data.noOfFemaleFounders}</h2>
            <p className="text-xxs sm:text-xs md:text-sm">Female Founders</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <FaRocket className="text-base sm:text-lg md:text-xl lg:text-2xl" />
          <div className="text-left">
            <h2 className="text-xs sm:text-sm md:text-base font-bold">{data.noOfHires}</h2>
            <p className="text-xxs sm:text-xs md:text-sm">Number of Hires</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <FaMoneyBillWave className="text-base sm:text-lg md:text-xl lg:text-2xl" />
          <div className="text-left">
            <h2 className="text-xs sm:text-sm md:text-base font-bold">${data.totalCapital.toLocaleString()}</h2>
            <p className="text-xxs sm:text-xs md:text-sm">Total Capital</p>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
