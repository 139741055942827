import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    RespondToAuthChallengeCommand,
  } from '@aws-sdk/client-cognito-identity-provider';
  import crypto from 'crypto-js';
  
  const client = new CognitoIdentityProviderClient({ region: 'ca-central-1' });
  
  const calculateSecretHash = (username: string, clientId: string, clientSecret: string): string => {
    return crypto
      .HmacSHA256(username + clientId, clientSecret)
      .toString(crypto.enc.Base64);
  };
  
  export const signIn = async (username: string, password: string): Promise<any> => {
    const clientId = process.env.REACT_APP_CLIENT_ID || '';
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET || '';
  
    const command = new InitiateAuthCommand({
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: clientId,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
        SECRET_HASH: calculateSecretHash(username, clientId, clientSecret),
      },
    });
  
    try {
      const response = await client.send(command);
  
      if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          ChallengeName: 'NEW_PASSWORD_REQUIRED',
          Session: response.Session,
          ChallengeParameters: response.ChallengeParameters,
        };
      }
  
      return response.AuthenticationResult;
    } catch (err) {
      console.error('Sign-in failed:', err);
      throw err;
    }
  };
  
  export const completeNewPassword = async (
    username: string,
    newPassword: string,
    session: string | null,
  ): Promise<any> => {
    const clientId = process.env.REACT_APP_CLIENT_ID || '';
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET || '';
  
    const command = new RespondToAuthChallengeCommand({
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ClientId: clientId,
      ChallengeResponses: {
        USERNAME: username,
        NEW_PASSWORD: newPassword,
        SECRET_HASH: calculateSecretHash(username, clientId, clientSecret),
      },
      Session: session || "",
    });
  
    try {
      const response = await client.send(command);
      return response.AuthenticationResult;
    } catch (err) {
      console.error('Failed to complete new password challenge:', err);
      throw err;
    }
  };
  
  export const isAuthenticated = (): boolean => {
    return localStorage.getItem('accessToken') !== null;
  };
  
  export const getAccessToken = (): string | null => {
    return localStorage.getItem('accessToken');
  };
  
  export const signOut = (): void => {
    localStorage.removeItem('accessToken');
  };
  