import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const UploadFiles = () => {
  const [crmFile, setCrmFile] = useState<File | null>(null);
  const [mondayFile, setMondayFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const navigate = useNavigate();

  const handleCrmFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setCrmFile(file);
  };

  const handleMondayFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setMondayFile(file);
  };

  const handleUpload = async () => {
    if (!crmFile || !mondayFile) {
      setUploadStatus('Please upload both CRM and Monday files.');
      return;
    }

    const formData = new FormData();
    formData.append('crmFile', crmFile!);
    formData.append('mondayFile', mondayFile!);

    try {
      setUploadStatus('Uploading files...');
      const token = localStorage.getItem('accessToken');

      if (!token) {
        setUploadStatus('Authentication failed. Please log in.');
        navigate('/login');
        return;
      }

      const response = await fetch('https://api.sparkcentre.org/upload/', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
      });

      if (response.status === 401) {
        const result = await response.json();
        if (result.message === 'Token has expired!') {
          localStorage.removeItem('accessToken'); // Remove expired token
          navigate('/login'); // Redirect to login
        } else {
          setUploadStatus('Unauthorized access. Please log in.');
        }
        return;
      }

      if (response.ok) {
        setUploadStatus('Files uploaded successfully!');
        navigate('/');
      } else {
        const result = await response.json();
        setUploadStatus(`Error: ${result.error || 'File upload failed'}`);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadStatus('Error uploading files.');
    }
  };

  const handleGeneratePeriodicTable = () => {
    setUploadStatus('Generating Periodic Table...');
    navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white p-6">
      <div className="max-w-lg w-full bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Upload Files to Generate Periodic Table</h2>

        <div className="mb-6">
          <label className="block mb-2 text-sm font-semibold text-gray-600">CRM File Upload</label>
          <div className="flex items-center">
            <label className="w-full flex justify-center items-center px-4 py-2 bg-gray-50 text-blue-500 rounded-lg shadow-sm cursor-pointer hover:bg-blue-50 hover:text-blue-600">
              <span>Select CRM File</span>
              <input
                type="file"
                onChange={handleCrmFileChange}
                className="hidden"
              />
            </label>
          </div>
          {crmFile && <p className="text-sm text-gray-500 mt-2">Selected file: {crmFile.name}</p>}
        </div>

        <div className="mb-6">
          <label className="block mb-2 text-sm font-semibold text-gray-600">Monday File Upload</label>
          <div className="flex items-center">
            <label className="w-full flex justify-center items-center px-4 py-2 bg-gray-50 text-blue-500 rounded-lg shadow-sm cursor-pointer hover:bg-blue-50 hover:text-blue-600">
              <span>Select Monday File</span>
              <input
                type="file"
                onChange={handleMondayFileChange}
                className="hidden"
              />
            </label>
          </div>
          {mondayFile && <p className="text-sm text-gray-500 mt-2">Selected file: {mondayFile.name}</p>}
        </div>

        <button
          className={`w-full text-white font-semibold py-2 px-4 rounded-lg mb-4 transition-all ${
            crmFile && mondayFile
              ? 'bg-blue-500 hover:bg-blue-600 cursor-pointer'
              : 'bg-gray-300 cursor-not-allowed'
          }`}
          onClick={handleUpload}
          disabled={!crmFile || !mondayFile}
        >
          Upload Files
        </button>

        {uploadStatus && <p className="mt-4 text-sm text-center text-gray-700">{uploadStatus}</p>}

        {crmFile && mondayFile && (
          <button
            className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition-all mt-4"
            onClick={handleGeneratePeriodicTable}
          >
            Generate Periodic Table
          </button>
        )}
      </div>

      <div className="mt-8">
        <Link
          to="/"
          className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default UploadFiles;
