import React, { useState } from 'react';
import { signIn, completeNewPassword } from '../constants/cognitoAuth';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [session, setSession] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    try {
      const response = await signIn(username, password);
  
      if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsNewPasswordRequired(true);
        setSession(response.Session);
      } else {
        const accessToken = response?.AccessToken;
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          window.location.href = '/';
        } else {
          setError('Failed to retrieve access token.');
        }
      }
    } catch (err: any) {
      if (err.__type === 'NotAuthorizedException') {
        setError(err.message || 'Login failed. Please check your credentials.');
      } else if (err.__type === 'UserNotFoundException') {
        setError('User not found. Please check your username or sign up.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleNewPasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await completeNewPassword(username, newPassword, session);
      const accessToken = response?.AccessToken;
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        window.location.href = '/';
      } else {
        setError('Failed to retrieve access token after password update.');
      }
    } catch (err: any) {
      setError('Failed to update password. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <h1 className="text-4xl font-bold mb-8">Login to Spark Centre</h1>

      {!isNewPasswordRequired ? (
        <form className="w-full max-w-md bg-white p-8 rounded shadow-md" onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              id="username"
              type="text"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-red-500 text-xs mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600">
            Login
          </button>
        </form>
      ) : (
        <form className="w-full max-w-md bg-white p-8 rounded shadow-md" onSubmit={handleNewPasswordSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new-password">
              New Password
            </label>
            <input
              id="new-password"
              type="password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-red-500 text-xs mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600">
            Submit New Password
          </button>
        </form>
      )}

      <p className="mt-4 text-gray-600">
        Don't have an account?{' '}
        <a href="/signup" className="text-indigo-600 hover:underline">
          Sign up here
        </a>
      </p>
    </div>
  );
};

export default Login;
