import React from 'react';
import { FiDownload, FiHome } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MockData } from '../types';

interface FooterProps {
  data: MockData;
  downloadAsImage: () => void;
  downloadAsPDF: () => void;
  isCapturing: boolean;
}

function Footer({ data, downloadAsImage, downloadAsPDF, isCapturing }: FooterProps) {
  const allTechnologies = [
    ...data.areaOfTechnology.left,
    ...data.areaOfTechnology.middle,
    ...data.areaOfTechnology.right,
    ...data.areaOfTechnology.bottom,
  ];

  const splitTechnologiesIntoColumns = (technologies: typeof allTechnologies, itemsPerColumn: number) => {
    const columns = [];
    for (let i = 0; i < technologies.length; i += itemsPerColumn) {
      columns.push(technologies.slice(i, i + itemsPerColumn));
    }
    return columns;
  };

  const columns = splitTechnologiesIntoColumns(allTechnologies, 5);

  return (
    <footer className="bg-gray-300 text-black p-3 lg:p-4 flex flex-col lg:flex-row lg:justify-between lg:items-start space-y-6 lg:space-y-0">
      <div className="w-full lg:w-4/5">
        <p className="text-sm md:text-base lg:text-base font-bold mb-2">Areas of Technologies</p>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-7">
          {columns.map((column, columnIndex) => (
            <div key={columnIndex} className="flex flex-col">
              {column.map((tech, techIndex) => (
                <div key={techIndex} className="flex items-center mb-1">
                  <div
                    className="w-3 h-3 md:w-3 md:h-3 lg:w-2.5 lg:h-2.5 rounded-full mr-1"
                    style={{ backgroundColor: tech.color }}
                  ></div>
                  <p className="text-xs md:text-sm lg:text-xs font-roboto-condensed font-bold">{tech.name}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="w-full lg:w-auto flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 justify-end">
        <div className="download-buttons flex flex-row lg:flex-col space-x-3 justify-items-center justify-center lg:space-x-0 lg:space-y-3">
          <button
            title="Download Image"
            onClick={downloadAsImage}
            className={`bg-blue-500 p-1 rounded hover:bg-blue-600 ${isCapturing ? 'hidden' : ''}`}
          >
            <FiDownload size={16} className="text-white" />
          </button>

          <button
            title="Download PDF"
            onClick={downloadAsPDF}
            className={`bg-green-500 p-1 rounded hover:bg-green-600 ${isCapturing ? 'hidden' : ''}`}
          >
            <FiDownload size={16} className="text-white" />
          </button>

          <Link
            title="Go Back Home"
            to="/"
            className={`bg-gray-500 p-1 rounded hover:bg-gray-600 ${isCapturing ? 'hidden' : ''}`}
          >
            <FiHome size={16} className="text-white" />
          </Link>
        </div>

        <div className="text-left lg:w-auto w-full">
          <p className="text-sm md:text-base lg:text-base font-bold bg-gray-700 text-white p-1 rounded-b-none">
            Capital Investment by Business
          </p>
          <div className="flex flex-col gap-1 bg-gray-100 p-2 rounded-t-none">
            <div className="flex items-start">
              <p className="text-xs md:text-sm lg:text-xs font-bold">
                $ - ${data.capitalInvestmentByBusiness.none[0].toLocaleString()} - ${data.capitalInvestmentByBusiness.none[1].toLocaleString()}
              </p>
            </div>
            <div className="flex items-start">
              <p className="text-xs md:text-sm lg:text-xs font-bold">
                $$ - ${data.capitalInvestmentByBusiness.low[0].toLocaleString()} - ${data.capitalInvestmentByBusiness.low[1].toLocaleString()}
              </p>
            </div>
            <div className="flex items-start">
              <p className="text-xs md:text-sm lg:text-xs font-bold">
                $$$ - ${data.capitalInvestmentByBusiness.medium[0].toLocaleString()} - ${data.capitalInvestmentByBusiness.medium[1].toLocaleString()}
              </p>
            </div>
            <div className="flex items-start">
              <p className="text-xs md:text-sm lg:text-xs font-bold">
                $$$$ - ${data.capitalInvestmentByBusiness.high[0].toLocaleString()}+
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
