import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';

const SpinnerWithColors: React.FC = () => {
  const [currentColor, setCurrentColor] = useState<string>("#ba3826");

  const colorChangeInterval = 500;

  useEffect(() => {
    let colorIndex = 0;
    const colors = ["#c5c42f","#7cd039","#005f99","#562379"];

    const intervalId = setInterval(() => {
      colorIndex = (colorIndex + 1) % colors.length;
      setCurrentColor(colors[colorIndex]);
    }, colorChangeInterval);

    return () => clearInterval(intervalId);
  });

  return (
    <div className="flex justify-center items-center h-screen">
      <ClipLoader size={75} color={currentColor} speedMultiplier={0.75}/>
      <span className="text-black text-3xl ml-4">Loading data...</span>
    </div>
  );
};

export default SpinnerWithColors;
