import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import PeriodicTable from './PeriodicTable';
import Footer from './Footer';
import { MockData } from '../types';
import { ClipLoader } from 'react-spinners';
import SpinnerWithColors from "./SpinnerWithColors";

interface HomePageProps {
  downloadAsImage: () => void;
  downloadAsPDF: () => void;
  isCapturing: boolean;
}

const HomePage: React.FC<HomePageProps> = ({ downloadAsImage, downloadAsPDF, isCapturing }) => {
  const { timeframe } = useParams();
  const [data, setData] = useState<MockData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPeriodicTableData = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          setError('Authentication required.');
          navigate('/login');
          return;
        }

        if (!timeframe) {
          setError('Invalid timeframe.');
          setLoading(false);
          return;
        }

        const response = await fetch(`https://api.sparkcentre.org/process?timeframe=${timeframe}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 401) {
          navigate('/login');
          return;
        }

        if (!response.ok) throw new Error('Failed to fetch data');
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError('Error fetching periodic table data');
      } finally {
        setLoading(false);
      }
    };

    fetchPeriodicTableData();
  }, [timeframe, navigate]);

  const generateLeaves = () => {
    const leaves = [];
    const leafImages = [
      '/canada_leaf01.png',
      '/canada_leaf02.png',
      '/canada_leaf03.png'
    ];

    for (let i = 0; i < 10; i++) {
      const randomTop = Math.random() * 90;
      const randomLeft = Math.random() * 90;
      const randomSize = 100 + Math.random() * 100;
      const randomRotation = Math.random() * 360;
      const randomLeaf = leafImages[Math.floor(Math.random() * leafImages.length)];

      leaves.push(
        <img
          key={i}
          src={randomLeaf}
          alt="Canada Leaf"
          className="absolute z-0"
          style={{
            top: `${randomTop}%`,
            left: `${randomLeft}%`,
            width: `${randomSize}px`,
            height: `${randomSize}px`,
            transform: `rotate(${randomRotation}deg)`,
            position: 'absolute',
          }}
        />
      );
    }
    return leaves;
  };

  if (loading) {
    return <SpinnerWithColors />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="text-red-500">{error}</span>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="text-white">No data available</span>
      </div>
    );
  }

  return (
    <>
      {isCapturing && (
        <div id="loader-overlay" className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50">
          <ClipLoader size={50} color="#ffffff" loading={isCapturing} />
        </div>
      )}
      <div id="content-to-capture" className="w-full flex flex-col min-h-screen">
        <div className="absolute inset-0 overflow-hidden z-0">
          {generateLeaves()}
        </div>

        <div className="relative z-10 flex-grow w-full">
          <Header data={data} />
          <main className="flex-grow w-full">
            <PeriodicTable data={data.periodicTable.data} technologies={data.areaOfTechnology} />
          </main>
        </div>

        <div className="z-50 mt-auto">
          <Footer data={data} downloadAsImage={downloadAsImage} downloadAsPDF={downloadAsPDF} isCapturing={isCapturing} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
