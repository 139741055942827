export interface DecodedToken {
  exp: number;
  iat?: number;
  [key: string]: any;
}

export const base64UrlDecode = (str: string): string => {
  try {
    const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    const decodedData = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return decodedData;
  } catch (error) {
    console.error('Base64 decoding failed:', error);
    return '';
  }
};

export const isTokenValid = (token: string): boolean => {
  if (!token) return false;

  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid token format');
    }

    const payload = JSON.parse(base64UrlDecode(parts[1])) as DecodedToken;

    if (!payload.exp) {
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp > currentTime;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
};
