import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { FiTable } from 'react-icons/fi';
import { FaAtom, FaFlask, FaMicroscope } from 'react-icons/fa';
import { FiUpload, FiEdit } from 'react-icons/fi';
import HomePage from './components/HomePage';
import UploadFiles from './components/UploadFiles';
import UpdateAreaOfTech from './components/UpdateAreaOfTech';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import Signup from './components/Signup';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isAuthenticated, getAccessToken } from './constants/cognitoAuth';
import { isTokenValid } from './utils/tokenValidation';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authChecking, setAuthChecking] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isAuthenticated();
      const token = getAccessToken() || "";

      if (authenticated && isTokenValid(token)) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setAuthChecking(false);
    };
    checkAuth();
  }, []);

  const loadFonts = async (): Promise<void> => {
    await document.fonts.ready;
  };

  const downloadAsImage = async (): Promise<void> => {
    setLoading(true);
    await loadFonts();

    const elementsToMove = document.querySelectorAll('.subpixel-antialiased');
    const dollarSigns = document.querySelectorAll('.capital-investment-type'); // Select the dollar signs
    const abbreviations = document.querySelectorAll('.abbreviation'); // Select the abbreviations
    const boxText = document.querySelectorAll('.periodic-text');

    elementsToMove.forEach((el)=>{
      const element = el as HTMLElement
    const originalText = element.textContent || ''; // Get the current text
  if (originalText.length > 12) {
    element.textContent = originalText.slice(0, 12) + '..'; // Truncate to 15 characters and add ellipsis
  }

      element.style.lineHeight = '2';
      element.style.paddingBottom = '5%';
      element.style.transform = 'translateY(-20%)';
      element.style.whiteSpace = 'nowrap';
      element.style.overflow = 'hidden';
      element.style.textOverflow = 'ellipsis'; // Add ellipsis
    });
      // Move the dollar signs up a bit
    dollarSigns.forEach((el) => {
      const element = el as HTMLElement;
      element.style.transform = 'translateY(-35%)'; // Move the dollar sign up slightly
    });

    // Move the abbreviations down a bit
    abbreviations.forEach((el) => {
      const element = el as HTMLElement;
      element.style.transform = 'translateY(20%)'; // Move the abbreviation down slightly
    });
    boxText.forEach((el)=>{
      const element = el as HTMLElement;
      element.style.transform = 'translateY(-18%)';
    });

    const element = document.getElementById('content-to-capture');
    if (!element) return;

    html2canvas(element, { scale: 4, useCORS: true, allowTaint: false, logging: true })
      .then((canvas) => {
        const link = document.createElement('a');
        link.download = 'periodic-table-snapshot.png';
        link.href = canvas.toDataURL();
        link.click();

        elementsToMove.forEach((el)=>{
          const element = el as HTMLElement
          element.style.lineHeight = '';
          element.style.paddingBottom = '';
          element.style.transform = '';
          element.style.whiteSpace = '';
          element.style.overflow = '';
          element.style.textOverflow = '';
        });

        dollarSigns.forEach((el) => {
          const element = el as HTMLElement;
          element.style.transform = '';
        });

        abbreviations.forEach((el) => {
          const element = el as HTMLElement;
          element.style.transform = '';
        });
        boxText.forEach((el)=>{
          const element = el as HTMLElement;
          element.style.transform = '';
        });

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const downloadAsPDF = async (): Promise<void> => {
    setLoading(true);
    await loadFonts();
    

    const elementsToMove = document.querySelectorAll('.subpixel-antialiased');
    const dollarSigns = document.querySelectorAll('.capital-investment-type');
    const abbreviations = document.querySelectorAll('.abbreviation');
    const boxText = document.querySelectorAll('.periodic-text');

    elementsToMove.forEach((el)=>{
      const element = el as HTMLElement
      const originalText = element.textContent || '';
      if (originalText.length > 12) {
        element.textContent = originalText.slice(0, 12) + '..';
      }
      element.style.lineHeight = '2';
      element.style.paddingBottom = '5%';
      element.style.transform = 'translateY(-20%)';
      element.style.whiteSpace = 'nowrap';
      element.style.overflow = 'hidden';
      element.style.textOverflow = 'ellipsis';
    });
    dollarSigns.forEach((el) => {
      const element = el as HTMLElement;
      element.style.transform = 'translateY(-35%)';
    });

    abbreviations.forEach((el) => {
      const element = el as HTMLElement;
      element.style.transform = 'translateY(20%)';
    });
    boxText.forEach((el)=>{
      const element = el as HTMLElement;
      element.style.transform = 'translateY(-18%)';
    });

    const element = document.getElementById('content-to-capture');
    if (!element) return;

    html2canvas(element, { scale: 4, useCORS: true, allowTaint: false })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ orientation: 'landscape', unit: 'px', format: [canvas.width, canvas.height] });
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save('periodic-table.pdf');
        setLoading(false);
        elementsToMove.forEach((el)=>{
          const element = el as HTMLElement
          element.style.lineHeight = '';
          element.style.paddingBottom = '';
          element.style.transform = '';
          element.style.whiteSpace = '';
          element.style.overflow = '';
          element.style.textOverflow = '';
        });

        dollarSigns.forEach((el) => {
          const element = el as HTMLElement;
          element.style.transform = '';
        });

        abbreviations.forEach((el) => {
          const element = el as HTMLElement;
          element.style.transform = '';
        });
        boxText.forEach((el)=>{
          const element = el as HTMLElement;
          element.style.transform = '';
        });
      }).catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  if (authChecking) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <ClipLoader size={50} color="#000" loading={true} />
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen flex flex-col items-center justify-center bg-white">
        {loading && (
          <div id="loader-overlay" className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <ClipLoader size={50} color="#ffffff" loading={loading} />
          </div>
        )}
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <>
                  <div className="text-center mb-10">
                    <h1 className="text-4xl font-bold text-gray-800">Spark Centre</h1>
                    <p className="text-gray-600 mt-4">Select a page to navigate</p>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <Link to="/periodicTable/0" className="flex flex-col items-center text-gray-800 hover:text-green-600">
                      <FiTable className="text-6xl mb-2" />
                      <span className="text-xl">Periodic Table (Local and Landed)</span>
                    </Link>
                    <Link to="/periodicTable/12" className="flex flex-col items-center text-gray-800 hover:text-green-600">
                      <FaAtom className="text-6xl mb-2" />
                      <span className="text-xl">Periodic Table (0 - 12 months)</span>
                    </Link>
                    <Link to="/periodicTable/36" className="flex flex-col items-center text-gray-800 hover:text-green-600">
                      <FaFlask className="text-6xl mb-2" />
                      <span className="text-xl">Periodic Table (12 - 24 months)</span>
                    </Link>
                    <Link to="/periodicTable/48" className="flex flex-col items-center text-gray-800 hover:text-green-600">
                      <FaMicroscope className="text-6xl mb-2" />
                      <span className="text-xl">Periodic Table (+24 months)</span>
                    </Link>
                    <Link to="/uploadFiles" className="flex flex-col items-center text-gray-800 hover:text-red-600">
                      <FiUpload className="text-6xl mb-2" />
                      <span className="text-xl">Upload Files</span>
                    </Link>
                    <Link to="/updateAreaOfTech" className="flex flex-col items-center text-gray-800 hover:text-purple-600">
                      <FiEdit className="text-6xl mb-2" />
                      <span className="text-xl">Update Area of Tech</span>
                    </Link>
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          <Route
            path="/periodicTable/:timeframe"
            element={
              <ProtectedRoute>
                <HomePage downloadAsImage={downloadAsImage} downloadAsPDF={downloadAsPDF} isCapturing={loading} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/uploadFiles"
            element={
              <ProtectedRoute>
                <UploadFiles />
              </ProtectedRoute>
            }
          />
          <Route
            path="/updateAreaOfTech"
            element={
              <ProtectedRoute>
                <UpdateAreaOfTech />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
