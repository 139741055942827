import React from 'react';
import { PeriodicTableItem, AreaOfTechnology } from '../types';

interface PeriodicTableProps {
  data: PeriodicTableItem[];
  technologies: AreaOfTechnology;
}

function PeriodicTable({ data, technologies }: PeriodicTableProps) {
  const totalCompanies = data.length;

  const itemSize = totalCompanies > 150 ? 50 : totalCompanies > 100 ? 65 : totalCompanies > 50 ? 70 : 90;
  const maxItemsPerColumn = totalCompanies > 180 ? 7 : totalCompanies > 160 ? 6 : totalCompanies > 120 ? 5 : totalCompanies > 50 ? 4 : 3;
  const maxItemsPerColumnLeft = maxItemsPerColumn + 1;

  const abbreviationFontSize = itemSize * 0.19;
  const companyNameFontSize = itemSize * 0.18;
  const investmentTypeFontSize = itemSize * 0.18;

  const getColorForTechnology = (techName: string) =>
    [...technologies.left, ...technologies.middle, ...technologies.right, ...technologies.bottom].find(
      (tech) => tech.name === techName
    )?.color || '#cccccc';

  const filterCompaniesByTechnologyOrder = (items: PeriodicTableItem[], techList: { name: string }[]) =>
    techList.reduce((ordered, tech) => [...ordered, ...items.filter((item) => item.areaOfTechnology === tech.name)], [] as PeriodicTableItem[]);

  const leftQuadrant = filterCompaniesByTechnologyOrder(data, technologies.left);
  const middleQuadrant = filterCompaniesByTechnologyOrder(data, technologies.middle);
  const rightQuadrant = filterCompaniesByTechnologyOrder(data, technologies.right);

  const noTechnologyCompanies = data.filter(
    (item) => !item.areaOfTechnology || !Object.values(technologies).flat().some((tech) => tech.name === item.areaOfTechnology)
  );
  const bottomQuadrant = [...filterCompaniesByTechnologyOrder(data, technologies.bottom), ...noTechnologyCompanies];

  const renderCell = (item: PeriodicTableItem) => (
    <div
      key={item.abbreviation}
      className="relative group px-0.5 py-2 text-center rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:scale-105 bg-white border border-gray-300 z-[1]"
      style={{
        backgroundColor: getColorForTechnology(item.areaOfTechnology),
        height: `${itemSize * 1.1}px`,
        width: `${itemSize}px`,
      }}
    >
      <div className="periodic-text">
        <h3 className="font-semibold text-white truncate uppercase subpixel-antialiased abbreviation" style={{ fontSize: `${abbreviationFontSize}px` }}>
          {item.abbreviation}
        </h3>
        <p className="text-white truncate font-semibold subpixel-antialiased leading-relaxed" style={{ fontSize: `${companyNameFontSize}px` }}>
          {item.companyName}
        </p>
        <p className="text-white font-semibold subpixel-antialiased capital-investment-type" style={{ fontSize: `${investmentTypeFontSize}px` }}>
          {item.capitalInvestmentType === 'none' && '$'}
          {item.capitalInvestmentType === 'low' && '$$'}
          {item.capitalInvestmentType === 'medium' && '$$$'}
          {item.capitalInvestmentType === 'high' && '$$$$'}
        </p>
      </div>
      <div className="absolute hidden group-hover:flex flex-col items-center text-xs bg-gray-900 text-white p-3 rounded-lg shadow-lg w-48 -top-28 left-1/2 transform -translate-x-40 z-[200]">
        <p className="font-bold">{item.companyName}</p>
        <p>Abbreviation: {item.abbreviation}</p>
        <p>Investment Type: {item.capitalInvestmentType}</p>
        <p>Area of Technology: {item.areaOfTechnology || 'N/A'}</p>
      </div>
    </div>
  );

  const createDynamicGrid = (items: PeriodicTableItem[], maxItems: number) => {
    const numColumns = Math.ceil(items.length / maxItems);
    const columns = Array.from({ length: numColumns }, (_, i) => items.slice(i * maxItems, (i + 1) * maxItems));

    return (
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-center gap-2"
        style={{ gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))` }}
      >
        {columns.map((col, colIndex) => (
          <div key={colIndex} className="flex flex-col space-y-1.5">
            {col.map(renderCell)}
          </div>
        ))}
      </div>
    );
  };

  const maxBottomRows = 2;
  const maxItemsPerBottomRow = Math.ceil(bottomQuadrant.length / maxBottomRows);
  const createBottomGrid = (items: PeriodicTableItem[], maxItems: number) => {
    const numRows = Math.min(maxBottomRows, Math.ceil(items.length / maxItems));
    const rows = Array.from({ length: numRows }, (_, i) => items.slice(i * maxItems, (i + 1) * maxItems));

    return (
      <div className="mt-10 flex justify-center">
        <div className={`grid grid-cols-${maxItems} gap-2 justify-center`}>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="flex space-x-1.5">
              {row.map(renderCell)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 mx-5">
      <div className="flex flex-col md:flex-row justify-center gap-8 items-end">
        <div className="w-auto">{createDynamicGrid(leftQuadrant, maxItemsPerColumnLeft)}</div>
        <div className="w-auto">{createDynamicGrid(middleQuadrant, maxItemsPerColumn)}</div>
        <div className="w-auto">{createDynamicGrid(rightQuadrant, maxItemsPerColumn)}</div>
      </div>
      <div>{createBottomGrid(bottomQuadrant, maxItemsPerBottomRow)}</div>
    </div>
  );
}

export default PeriodicTable;
